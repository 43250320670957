import { call, put, select, takeLatest } from 'redux-saga/effects';
import { groupsActions, groupsTypes } from './index';
import axios from 'axios';
import { LoginSelectors } from '../Login';
import { searchSelectors } from '../search';

function* loadGroups({
                         type,
                         key,
                         value,
                         page,
                         pageSize,
                     }: { type: string, key: string, value: string, page: number, pageSize: number }): any {
    const token: string = yield select(LoginSelectors.getToken);

    // @ts-ignore
    const extendedValues: any = yield select(searchSelectors.getExtendedValues);

    let { data } = yield axios.post(`/api/document/group`, {
        keys: [{
            key: key, value: value,
        }],
        ...extendedValues,
    }, {
        params: {
            page: page - 1, size: pageSize,
            sort: 'created,desc',

        },
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });

    if (data.numberOfElements === 0 && page > 0) {
        console.log('wrong page - reset to 0');
        yield call(loadGroups, { type, key, value, page: 0, pageSize });
        return;
    }

    let { data: meta } = yield axios.post(`/api/document/group/meta`, {
        keys: [{
            key: key, value: value,
        }],
        ...extendedValues,
    }, {
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });
    console.log('meta', meta);

    yield put(groupsActions.loadGroupsDone(data, meta));
}

export default function* groupsSaga() {
    yield takeLatest(groupsTypes.LOAD_GROUPS, loadGroups);
}
