import React, { useEffect, useState } from 'react';
import './DocumentGroupScreen.less';
import { Button, Card, Col, Row, Space, Table } from 'antd';
import MediaTypeIcon from '../../components/MediaTypeIcon/MediaTypeIcon';
import DateTimeSpan, { DateFormat } from '../../components/DateTimeSpan/DateTimeSpan';
import TagLabel from '../../components/TagLabel/TagLabel';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { groupsActions, groupsSelectors } from '../../redux/groups';
import { getColorFromString } from '../../helper/ColorHelper';
import Icon, { InlineIcon } from '../../components/Icon/Icon';
import { documentsActions } from '../../redux/documents';
import DocumentPreview from '../common/DocumentPreview/DocumentPreview';
import { translateTag } from '../../helper/TagHelper';
import Link from '../../components/Link/Link';
import { searchActions } from '../../redux/search';

const DocumentGroupScreen = ({}) => {

        const dispatch = useDispatch();
        const { key, value } = useParams<any>();

        const [page, setPage] = useState(1);
        const [pageSize, setPageSize] = useState<number | undefined>(25);

        useEffect(() => {
            dispatch(groupsActions.loadGroups(key, value, undefined, page, pageSize));
        }, [key, value, page, pageSize]);


        const commonKeys = useSelector(groupsSelectors.getCommonKeys);
        const commonKeysKeys = useSelector(groupsSelectors.getCommonKeysKeys);
        const data = useSelector(groupsSelectors.getData);
        const isLoading = useSelector(groupsSelectors.isLoading);

        const totalElements = useSelector(groupsSelectors.getNumberOfElements);
        const currentPage = useSelector(groupsSelectors.getCurrentPage);

        useEffect(() => {
            // pagination reset if external page changes
            if (currentPage !== undefined) {
                if (currentPage + 1 !== page) {
                    setPage(currentPage + 1);
                }
            }
        }, [currentPage]);

        return (
            <div className='document-group-screen'>
                <h1>Dokumente</h1>

                {commonKeys && commonKeys.length > 0 && (
                    <Card size={'small'} style={{ marginBottom: 4 }}>
                        <h3>Gemeinsame Schlüssel</h3>
                        {commonKeys?.map((key => {
                            return <Link to={`/group/${key.key}/${key.value}/`} key={key.key}>
                                <TagLabel label={key.value} color={getColorFromString(key.key)}>
                                    {key.readableKey || key.key}
                                </TagLabel>
                            </Link>;
                        }))}
                    </Card>
                )}

                <Table dataSource={data}
                       size={'small'}
                       loading={isLoading}
                       pagination={{
                           current: page,
                           total: totalElements,
                           pageSize: pageSize,
                           defaultPageSize: 25,
                           size: 'small',
                           onChange: (page, pageSize) => {
                               setPageSize(pageSize);
                               setPage(page);
                           },
                           showSizeChanger: false,
                       }}
                       columns={[
                           {
                               key: 'icon',
                               dataIndex: 'documentType',
                               render: (type, document) => {
                                   return <MediaTypeIcon mediaType={document.mediaType} />;
                               },
                               width: 40,
                           },
                           {
                               width: '1%',
                               key: 'tags',
                               title: 'Tags',
                               render: (type, documents) => {
                                   return <Space size={2} direction={'vertical'}>
                                       {documents.tags?.map((tag) => (
                                           <Row>
                                               <TagLabel label={tag.readableValue || tag.value}
                                                         color={getColorFromString(tag.key)}>{translateTag(tag.key)}</TagLabel>
                                           </Row>
                                       ))}
                                   </Space>;
                               },
                           },

                           {
                               title: 'Dateiname',
                               key: 'fileName',
                               dataIndex: 'fileName',
                               render: (fileName, document) => <Row>
                                   <Col span={24}>{fileName || <i>-unbekannter Name-</i>}</Col>
                                   <Space size={4} split={'|'}>
                                       <Col>
                                           <DocumentPreview document={document} />
                                       </Col>
                                       <Col>
                                           <Button
                                               style={{ padding: 0 }}
                                               onClick={() => {
                                                   dispatch(documentsActions.downloadDocument(document));
                                               }}
                                               size={'small'} type={'link'} icon={
                                               <InlineIcon style={{ marginRight: 4 }} size={'1rem'}
                                                           path={Icon.Path.mdiDownloadCircle} />
                                           }>Herunterladen</Button>
                                       </Col>
                                   </Space>
                               </Row>,
                           },
                           {
                               title: 'Erstellt',
                               width: 150,
                               key: 'eventCreated',
                               dataIndex: 'eventCreated',
                               render: (value, document) => <div style={{ fontSize: '0.9em', color: '#666' }}>
                                   <DateTimeSpan format={DateFormat.DATE_TIME_SHORT}>{value}</DateTimeSpan><br />
                                   {document.createdByUser}
                               </div>,
                           },
                           {
                               width: '1%',
                               key: 'keys',
                               title: 'Schlüssel',
                               render: (type, documents) => {
                                   return <Space size={2} direction={'vertical'}>
                                       {documents.keys?.sort((a, b) => (a.readableKey || a.key).localeCompare(b.readableKey || b.key)).filter(key => !commonKeysKeys?.includes(key.key))
                                           .map((key) => (
                                               <Link to={`/group/${key.key}/${key.value}/`} key={key.key}>
                                                   <TagLabel label={key.value}
                                                             color={getColorFromString(key.key)}>{key.readableKey || key.key}</TagLabel>
                                               </Link>
                                           ))}
                                   </Space>;
                               },
                           },

                       ]}
                />
            </div>
        );
    }
;

export default DocumentGroupScreen;
