import { AnyAction } from 'redux';
import { searchTypes } from './index';
import { DocumentSearchResultResponse } from '../../types';

export interface searchStateType {
    search: string,
    keyType: string,
    extendedValues?: any,
    loading: boolean,
    result?: DocumentSearchResultResponse,
    noSearch: boolean,
}


const initialState: searchStateType = {
    search: '',
    keyType: 'ANY',
    loading: false,
    result: undefined,
    noSearch: true,
};

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case searchTypes.SEARCH: {
            const {search, keyType, extendedValues} = action;
            return {
                ...state,
                search: search,
                extendedValues: extendedValues,
                keyType: keyType,
                loading: true,
                noSearch: false,
            };
        }
        case searchTypes.SEARCH_NO_SEARCH: {
            return {
                ...state,
                loading: false,
                result: undefined,
                noSearch: true,
            }
        }
        case searchTypes.SEARCH_RESULT: {
            let {result} = action;
            return {
                ...state,
                loading: false,
                result: result,
            }
        }
        default:
            return state;
    }
};

export default reducer;
