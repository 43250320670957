import React, { CSSProperties } from 'react';
import './Link.less';
import {useDispatch} from 'react-redux';
import {push} from 'connected-react-router';
import clsx from 'clsx';


type LinkProps = {
    to: string,
    children: React.ReactNode,
    style?: CSSProperties,
    className?: string,
}

const Link = ({to, children, style, className}: LinkProps) => {
    const dispatch = useDispatch();
    return (
        <a className={clsx('link', className)}
           style={style}
           href={to}
           onClick={(e) => {
               if (to.indexOf("#") !== 0) {
                   e.preventDefault();
                   dispatch(push(to));
               } else {

               }
           }}>
            {children}
        </a>
    );
};

export default Link;
