import chroma from 'chroma-js';

export const isDark = (color: string) => {
    return chroma(color).get('lab.l') < 70;
};


export const getColorFromString = (str: string | undefined) =>{
    if(!str)
        return "#cccccc"
    let hash = 0;
    if (str.length === 0) return '#cccccc';
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 255;
        color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
}
