// /* eslint-disable no-restricted-globals */
import React from 'react';
import { Menu } from 'antd';
import Icon from '../../../components/Icon/Icon'
import { useSelector } from 'react-redux';
import MenuItem from './menu.item';
import { routerSelectors } from '../../../redux/router';

const MainMenu = () => {
    const currentActivePath: string = useSelector(routerSelectors.getCurrentActiveRootPath);

    return (
        <Menu theme="dark" mode="horizontal" selectedKeys={[currentActivePath]}>
            <Menu.Item key={'/'}>
                <MenuItem
                    title={'Suche'}
                    path={'/'}
                    // icon={<AntMdiIcon path={IconsPath.mdiCalendarTextOutline}/>}
                />
            </Menu.Item>
        </Menu>
    );
};
export default MainMenu
