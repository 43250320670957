import React from 'react';
import { Route, Switch } from 'react-router';
import HomeScreen from './HomeScreen/HomeScreen';
import DocumentGroupScreen from './DocumentGroupScreen/DocumentGroupScreen';

const Routes = () => {
    return (
        <Switch>



            <Route path={'/document/:id'}>
                Dokument
            </Route>

            <Route path={'/group/:key/:value/'}>
                <DocumentGroupScreen />
            </Route>

            <Route path={'/'}>
                <HomeScreen />
            </Route>
        </Switch>
    );
};

export default Routes;
