import React, { useEffect, useState } from 'react';
import './HomeScreen.less';
import { Button, Card, Col, Empty, Row } from 'antd';
import SearchBar from '../../components/SearchBar/SearchBar';
import { DocumentSearchResult } from '../../types';
import SearchResultList from '../../components/SearchResultList/SearchResultList';
import { useDispatch, useSelector } from 'react-redux';
import { searchActions, searchSelectors } from '../../redux/search';
import qs from 'query-string';
import { useLocation, useParams } from 'react-router';
import { push, replace } from 'connected-react-router';

const HomeScreen = ({}) => {

    const dispatch = useDispatch();
    // const searchResults: DocumentSearchResult[] = [
    //     {
    //         key: 'MELO',
    //         value: '12345',
    //         readableKey: 'Messlokations ID',
    //         details: {
    //             count: 5,
    //         },
    //     },
    //     {
    //         key: 'MALO',
    //         value: '12345',
    //         readableKey: 'Marktlokations ID',
    //     },
    //     {
    //         key: 'MELO',
    //         value: '12346',
    //         readableKey: 'Messlokations ID',
    //     },
    //     {
    //         key: 'MELO',
    //         value: '12347',
    //         readableKey: 'Messlokations ID',
    //     },
    // ];

    const searchResults = useSelector(searchSelectors.getData);
    const totalElements = useSelector(searchSelectors.getNumberOfElements);
    const currentPageSize = useSelector(searchSelectors.getPageSize);
    const currentPage = useSelector(searchSelectors.getCurrentPage);
    const isLoading = useSelector(searchSelectors.isLoading);

    const noSearch = useSelector(searchSelectors.noSearch);

    const location = useLocation();

    const searchParams = qs.parse(location.search);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState<number | undefined>(25);

    // const [searchText, setSearchText] = useState('');
    // const [searchType, setSearchType] = useState("ANY");
    // const [extendedValues, setExtendedValues] = useState({});

    const searchText = (searchParams.s || "") as string;
    const searchType = (searchParams.type || "ANY") as string;

    console.log("SearchParams", searchParams)

    // const [search, setSearch] = useState({ text:  "", type:  "ANY", extendedValues: {} });

    const extendedValues = useSelector(searchSelectors.getExtendedValues);

    useEffect(()=>{
        // pagination reset if external page changes
        if(currentPage !== undefined) {
            if (currentPage + 1 !== page) {
                setPage(currentPage + 1);
            }
        }
    }, [currentPage])

    useEffect(() => {
        // console.log("search", search)
        dispatch(searchActions.search(searchText, searchType, extendedValues, page, pageSize));
    }, [page, pageSize]);

    useEffect(() => {
        // console.log("search", search)
        dispatch(searchActions.search(searchText, searchType, extendedValues, page, pageSize));
    }, []);

    // useEffect(()=>{
    //     console.log("location", location)
    //     // const query = {
    //     //     s: searchText,
    //     //     type: searchType,
    //     // }
    //     // dispatch(replace({search: qs.stringify(query)}))
    // }, [searchText, searchType])

    useEffect(()=>{
        console.log("loaded with params", searchParams);
        // if(searchParams.s !== search.text || searchParams.type !== search.type){
        //     // setSearch({...search, text: searchParams.s as string, type: searchParams.type as string})
        // }
        //
    }, [location.search])


    return (
        <div className='home-screen'>
            <h1>Suche</h1>

            <Row gutter={[4, 4]}>
                <Col span={24}>
                    <Card size={'small'}>
                        <SearchBar
                            extendedValues={extendedValues}
                            value={searchText}
                            documentType={searchType}
                            onChange={(text, type, extendedValues) => {
                                console.log('Main > On Change', text, type, extendedValues);
                                dispatch(searchActions.search(text, type, extendedValues, page, pageSize));
                                // setSearch({ text, type, extendedValues });
                                // setSearchText(searchText);
                                // setSearchType(type);2
                                const query = {
                                    s: text,
                                    type: type,
                                }
                                dispatch(replace({search: qs.stringify(query)}))

                            }}
                            onSubmit={(text, type, extendedValues) => {
                                console.log('Main > On Submit', text, type, extendedValues, page, pageSize);
                            }}

                        />
                    </Card>
                </Col>
                <Col span={24}>
                    {noSearch ? (
                        <Card>
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={<div>
                                    <p>
                                        Bitte nutzen Sie das Suchfeld, um z.B. nach einer Zählernummer oder MeLo zu suchen
                                    </p>
                                    <p>
                                        In den Ergebnissen werden nur <b>exakte</b> Übereinstimmungen angezeigt!
                                    </p>
                                </div>}/>
                        </Card>
                    ) : (
                        <SearchResultList
                            onPaginationChange={(page, pageSize) => {
                                console.log("on pagingation");
                                setPage(page);
                                setPageSize(pageSize);
                            }}
                            loading={isLoading}
                            pageSize={currentPageSize}
                            totalElements={totalElements}
                            currentPage={page}
                            results={searchResults} />
                    )}


                </Col>
            </Row>

        </div>
    );
};

export default HomeScreen;
