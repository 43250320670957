import PDFAttachment from "../PDFAttachment/PDFAttachment";
import {Alert, Image, Modal, notification} from "antd";
import React, {useEffect, useState} from "react";
import PreviewTextDocument from "../PreviewTextDocument";


type Props = {
    imageUrl?: string;
    visible: boolean;
    onVisibleChange: (visible: boolean) => void
    mediaType?: string;
}

const PreviewDocument = ({imageUrl, visible, onVisibleChange, mediaType}: Props) => {

    const [warningVisible, setWarningVisible] = useState(false);

    useEffect(() => {
        if (warningVisible) {
            notification.warn({
                message: "Vorschau nicht möglich!"
            })
        }
    }, [warningVisible])

    if (!imageUrl || !visible)
        return null;

    if (mediaType === 'application/pdf') {
        return (
            <PDFAttachment
                visible={visible}
                onClose={() => {
                    onVisibleChange(false);
                }}
                url={imageUrl} width={100} height={200}/>
        )
    } else if (mediaType?.startsWith("image/")) {
        return (
            <Image src={imageUrl}
                   height={0}
                   width={0}
                   preview={{
                       onVisibleChange: (visible, prevVisible) => {
                           console.log('visible changed', visible, prevVisible);
                           if (!visible)
                               onVisibleChange(false);
                       },
                       visible: visible,
                   }}/>
        )
    } else if (mediaType?.startsWith("text/") || mediaType === 'application/json') {
        return (
            <PreviewTextDocument visible={visible}
                                 mediaType={mediaType}
                                 onClose={() => {
                                     onVisibleChange(false)
                                 }}
                                 imageUrl={imageUrl}/>
        )
    }
    if (!warningVisible)
        setWarningVisible(true);
    return null;
}

export default PreviewDocument;
