import React from 'react';
import './User.less';
import { Popover } from 'antd';
import { splitJoin } from '../../helper/TextHelper';
import Avatar from '../Avatar/Avatar';
import OptionalLabeledRow from '../OptionalLabeledRow/OptionalLabeledRow';

const User = ({
    id,
    lastname,
    firstname,
    email,
    showAvatar = true,
    tinyAvatar = false,
    showName = true,
    popoverEnabled = true,
}: {
    id?: string;
    lastname?: string;
    firstname?: string;
    email?: string;
    showAvatar?: boolean;
    tinyAvatar?: boolean;
    showName?: boolean;
    popoverEnabled?: boolean;
}) => {
    if (!lastname && !firstname && !email && !id) {
        return null;
    }

    const popoverContent = (
        <div>
            <OptionalLabeledRow label={'Name'} labelSpan={24}>
                {splitJoin([firstname, lastname], ' ')}
            </OptionalLabeledRow>
            <OptionalLabeledRow label={'E-Mail'} labelSpan={24}>
                {email}
            </OptionalLabeledRow>
            <OptionalLabeledRow label={'Nutzerkennung'} labelSpan={24}>
                {id}
            </OptionalLabeledRow>
        </div>
    );
    const userContent = splitJoin([firstname, lastname]) || email;



    /*
    if showName is false and showAvatar is true:
    show only short variant of user (only avatar-icon)
     */
    if (showAvatar === true && showName === false) {

        /*
        guess firstname & lastname from email
         */
        if(!firstname && !lastname && email){
            let tmp = email.split("@")[0];
            if(tmp){
                let secondSplit = tmp.split(".");
                console.log('secondsplit', secondSplit)
                firstname = secondSplit[0];
                lastname = secondSplit[1];

                console.log("Set-> ", firstname, lastname);
            }
            console.log('split mail', tmp);
        }

        return (
            <div>
                <Avatar tiny={tinyAvatar} lastname={lastname || ' '} firstname={firstname || ' '} />
            </div>
        );
    }

    return (
        <Popover visible={popoverEnabled ? undefined : false} content={popoverContent}>
            <span className="ticket-user">
                {showAvatar && (
                    <Avatar tiny={tinyAvatar} lastname={lastname} firstname={firstname} />
                )}{' '}
                {userContent}
            </span>
        </Popover>
    );
};

export default User;
