import { put, takeLatest, delay, race, take, select, spawn, call } from 'redux-saga/effects';
import { LoginActions, LoginSelectors, LoginTypes } from './index';
import { AnyAction } from 'redux';
import axios from 'axios';
import { push } from 'connected-react-router';
import jwtDecode from 'jwt-decode';


function* login({
                    username,
                    password,
                    remember,
                }: { username: string, password: string, remember: boolean, type: string }) {

    // let { data } : any = yield axios.post('/auth/token', {}, {
    //     auth: {
    //         username,
    //         password,
    //     },
    // });

    try {
        let { data } = yield axios.post(`/auth/token`, {}, {
            auth: {
                username, password,
            },
            withCredentials: true,
        });

        let {exp} : {exp: number} = jwtDecode(data.token);
        yield put(LoginActions.loginDone(data.username, data.token, exp));

        // console.log('result', data);
    } catch (e) {
        yield put(LoginActions.loginError());
        console.log('Catch error', e);
    }

}


function* loginEnvagoError() {
    yield put(push('/'));
    yield put(LoginActions.loginError());
}

function* loginEnvago({ token, type }: any) {
    try{
        yield put(push("/"));
        let { data } = yield axios.post(`/auth/token`, {}, {
            headers: {
                "Authorization": `Envago ${token.accessToken}`
            },
            withCredentials: true,
        });

        let {exp} : {exp: number} = jwtDecode(data.token);

        // yield delay(1000);
        yield put(LoginActions.loginDone(data.username, data.token, exp));
    } catch(e){
        yield put(LoginActions.loginEnvagoError());
    }
}

function* logout() {
    yield put(push('/'));
}

function* watchTokenExpiry(expires: number){
    let expiresIn = expires - Math.floor(new Date().getTime() / 1000);

    console.log("Expires in ", expiresIn)

    if (expiresIn > 0) {
        let { logout } = yield race({
            wait: delay((expiresIn - 60) * 1000),
            logout: take(LoginTypes.LOGOUT),
        });
        if (logout) {
            return;
        }
    }
    yield put(LoginActions.logout());
}

function* watchTokenExpiryAfterReload(){
    yield delay(5000);
    let loggedIn : boolean = yield select(LoginSelectors.isLoggedIn);
    if(loggedIn){
        console.log("is logged in -> lets add token expiry process");
        let expires : number | undefined = yield select(LoginSelectors.getExpires);
        console.log("expires in...", expires)
        if(expires) {
            yield call(watchTokenExpiry, expires);
        }
    }
}

export default function* LoginSaga() {
    // console.log('LoginSaga loaded');
    yield spawn(watchTokenExpiryAfterReload);
    yield takeLatest(LoginTypes.LOGIN_DONE, watchTokenExpiryAfterReload);
    yield takeLatest(LoginTypes.LOGOUT, logout);
    yield takeLatest(LoginTypes.LOGIN, login);
    yield takeLatest(LoginTypes.LOGIN_ENVAGO, loginEnvago);
    yield takeLatest(LoginTypes.LOGIN_ENVAGO_ERROR, loginEnvagoError);
}
