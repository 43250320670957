import { delay, all, spawn, fork, take, call } from 'redux-saga/effects';
import LoginSaga from './Login/Login.saga';
import searchSaga from './search/search.saga';
import groupsSaga from './groups/groups.saga';
import documentsSaga from './documents/documents.saga';

export default function* rootSaga() {


    // console.log('Saga initialization done');

    yield spawn(LoginSaga);
    yield spawn(searchSaga);
    yield spawn(groupsSaga);
    yield spawn(documentsSaga)
}
