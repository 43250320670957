import React from 'react';
import './MediaTypeIcon.less';
import Icon from '../Icon/Icon';


const MediaTypeIcon = ({ mediaType }: { mediaType?: string }) => {

    switch (mediaType) {
        case 'text/plain':
        case 'text/csv':
        case 'text/rtf':
        case 'text/markdown':
            return <Icon size={18} style={{color: "#4f4f4f"}}  path={Icon.Path.mdiFileDocumentOutline} />;
        case 'application/json':
            return <Icon size={18} style={{color: "#4f4f4f"}}  path={Icon.Path.mdiFileCodeOutline} />;
        case 'application/pdf':
            return <Icon size={18} style={{color: "#a00c00"}}  path={Icon.Path.mdiFilePdfOutline} />;
        case 'image/jpeg':
        case 'image/png':
        case 'image/tiff':
            return <Icon size={18} style={{color: "#0040a0"}}  path={Icon.Path.mdiFileImageOutline} />;
        default:
            return <Icon size={18} style={{color: "#999"}} path={Icon.Path.mdiFileOutline} />;
    }

};

export default MediaTypeIcon;
