import React, { useState } from 'react';
import './PDFAttachment.less';
import { Button, Col, Modal, Row, Space } from 'antd';
import { Document, Page, pdfjs } from 'react-pdf';
import * as MdiPath from '@mdi/js';
import Icon from '../Icon/Icon';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFAttachment = ({ url, width, height, onClose, visible }) => {


    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    return (
        <div className='pdfattachment'>
            <Modal
                className={'pdf-modal'}
                width={'700px'}
                onOk={onClose}
                onCancel={onClose}
                footer={[
                    <Button type={'primary'} key='back' onClick={() => onClose()}>
                        Schließen
                    </Button>,
                ]}
                visible={visible}>
                <Document

                    file={url}
                    onLoadError={console.error} // eslint-disable-line no-console
                    onLoadSuccess={({ numPages }) => {
                        setNumPages(numPages);
                    }}
                >
                    <Page
                        className={'react-pdf-page'}
                        style={{ maxWidth: '100%' }}
                        pageNumber={pageNumber} />
                </Document>
                <Row style={{ marginTop: 16 }}>
                    <Col flex={'auto'}></Col>
                    <Col>
                        <Row style={{ alignItems: 'center' }}>
                            <Space>
                                <Button
                                    onClick={() => setPageNumber(pageNumber - 1)}
                                    disabled={pageNumber === 1}
                                    size={'small'} icon={<Icon path={Icon.Path.mdiArrowLeft} />} />
                                <span>Seite {pageNumber} von {numPages}</span>
                                <Button size={'small'}
                                        onClick={() => setPageNumber(pageNumber + 1)}
                                        disabled={pageNumber === numPages}
                                        icon={<Icon path={Icon.Path.mdiArrowRight} />} />
                            </Space>
                        </Row>
                    </Col>
                    <Col flex={'auto'}></Col>
                </Row>
            </Modal>
        </div>
    );
};

export default PDFAttachment;
