import React, { ReactNode, useEffect, useState } from 'react';
import './AuthenticationScreen.less';
import { useDispatch, useSelector } from 'react-redux';
import { LoginActions, LoginSelectors } from '../../redux/Login';
import { Alert, Button, Card, Divider, Form, Spin } from 'antd';
import ClientOAuth2 from 'client-oauth2';
// @ts-ignore
import { AuthorizationCodeCallback, RequestAuthorizationCode } from 'react-oauth2-auth-code-flow';
import { Route, Switch } from 'react-router';
import { push } from 'connected-react-router';
import config from '../../config';


const oauthClient = new ClientOAuth2({
    clientId: config.oauth.clientId,
    clientSecret: config.oauth.clientSecret,
    accessTokenUri: config.oauth.accessTokenUri,
    authorizationUri: config.oauth.authorizationUri,
    redirectUri: `${window.location.origin}/login/envago/callback`,
    scopes: ["login"],
});

const AuthenticationScreen = ({ children }: { children: ReactNode }) => {


    const isLoggedin = useSelector(LoginSelectors.isLoggedIn);
    const isLoading = useSelector(LoginSelectors.isLoading);

    const hasError = useSelector(LoginSelectors.hasError);

    const [errorVisible, setErrorVisible] = useState(false);

    const [form] = Form.useForm();

    useEffect(() => {
        if (hasError) {
            setErrorVisible(true);
            form.setFieldsValue({ password: '' });
        } else {
            setErrorVisible(false);
        }
    }, [hasError]);

    const dispatch = useDispatch();

    if (isLoggedin)
        return <>{children}</>;
    
    return (
        <Switch>
            <Route path={'/login/envago/callback'}>
                <AuthorizationCodeCallback
                    oauthClient={oauthClient}
                    onAuthSuccess={(accessToken: string, {state}: any) => {
                        console.log('Auth success', accessToken, state);
                        dispatch(LoginActions.loginEnvago(accessToken));
                        if(state.path){
                            dispatch(push(state.path + state.search));
                        }
                    }}
                    onAuthError={(err : any) => {
                        console.log("Auth error", err);
                        dispatch(LoginActions.loginEnvagoError());
                    }}
                    render={({ processing, state, error } : any) => (
                        <div>
                            {processing && <p>Authorizing now...</p>}
                            {error && (
                                <p className='error'>An error occurred: {error.message}</p>
                            )}
                        </div>
                    )}
                />
            </Route>
            <Route>
                <div className='authentication-screen screen' style={{backgroundColor: "#f1f1f1"}}>
                    <Card className={'centered'} style={{display: 'flex', justifyContent: 'center'}}>
                        <h1 style={{color: '#666'}}>Willkommen bei envago.archiv</h1>

                        <RequestAuthorizationCode
                            oauthClient={oauthClient}
                            state={{ path: window.location.pathname, search: window.location.search }}
                            render={({ url }: any) => <Button
                                size={"large"}
                                style={{color: '#fff'}}
                                loading={isLoading}
                                block={true} type={'primary'} href={url}>anmelden</Button>}
                        />
                        <br/><br/>
                        {isLoading && (
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <Spin tip="Anmeldung wird geprüft"/>
                            </div>
                        )}



                        {/*<Form*/}
                        {/*    form={form}*/}
                        {/*    onValuesChange={() => {*/}
                        {/*        console.log("Values change")*/}
                        {/*        setErrorVisible(false);*/}
                        {/*    }}*/}
                        {/*    onSubmitCapture={event => {*/}
                        {/*        event.preventDefault();*/}
                        {/*    }}*/}
                        {/*    onFinish={(values) => {*/}
                        {/*        console.log('Finish', values);*/}
                        {/*        setErrorVisible(false);*/}
                        {/*        if (!isLoading) {*/}
                        {/*            dispatch(LoginActions.login(values.username, values.password));*/}
                        {/*        }*/}
                        {/*    }}>*/}

                        {/*    <Form.Item*/}
                        {/*        name={'username'}*/}
                        {/*        rules={[{*/}
                        {/*            required: true,*/}
                        {/*            min: 5,*/}
                        {/*        }]}*/}
                        {/*    >*/}
                        {/*        <Input*/}
                        {/*            autoFocus={true}*/}
                        {/*            placeholder={'Benutzername'}*/}
                        {/*            prefix={<Icon style={{ color: '#999', fontSize: '1.5rem' }}*/}
                        {/*                          path={Icon.Path.mdiAccount} />} />*/}
                        {/*    </Form.Item>*/}

                        {/*    <Form.Item*/}
                        {/*        name={'password'}*/}
                        {/*        rules={[{*/}
                        {/*            required: true,*/}
                        {/*            min: 5,*/}
                        {/*        }]}*/}
                        {/*    >*/}
                        {/*        <Input*/}
                        {/*            type={'password'}*/}
                        {/*            placeholder={'Passwort'}*/}
                        {/*            prefix={<Icon style={{ color: '#999', fontSize: '1.5rem' }} path={Icon.Path.mdiLock} />} />*/}
                        {/*    </Form.Item>*/}

                        {/*    <Form.Item>*/}
                        {/*        <Button loading={isLoading} block={true} type={'primary'} htmlType={'submit'}>*/}
                        {/*            Anmelden*/}
                        {/*        </Button>*/}
                        {/*    </Form.Item>*/}
                        {/*</Form>*/}

                        {errorVisible && <Alert message={'Die Anmeldung war nicht möglich. Bitte prüfen Sie die notwendigen Rechte.'} type={'error'} />}

                        <Divider />
                        <div style={{color: "#999", fontSize: '0.8em', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            envago GmbH • Forststr. 9 • 04229 Leipzig<br/>
                            <a href={'https://www.envago.de'} target={'_blank'}>www.envago.de</a>
                        </div>

                        {/*<Button block={true}>*/}
                        {/*    mit envago.login anmelden*/}
                        {/*</Button>*/}

                    </Card>
                </div>
            </Route>
        </Switch>

    );
};

export default AuthenticationScreen;
