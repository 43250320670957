import { AnyAction } from 'redux';
import { LoginStateType } from './Login.types';
import { LoginActions, LoginTypes } from './index';

const initialState: LoginStateType = {
    loggedIn: false,
    loading: false,
    error: undefined,
    login: undefined,
};

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {

        case LoginTypes.LOGIN_ENVAGO:
        case LoginTypes.LOGIN: {
            return {
                ...state,
                loading: true,
                error: undefined,
                login: undefined,
            }
        }
        case LoginTypes.LOGIN_DONE: {
            const {username, token, expires} = action;
            return {
                ...state,
                loading: false,
                error: undefined,
                loggedIn: true,
                login: {
                    username, token, expires,
                }
            }
        }
        case LoginTypes.LOGIN_ERROR: {
            return {
                ...state,
                loading: false,
                error: new Date().getTime(),
                login: undefined,
            }
        }
        case LoginTypes.LOGOUT: {
            return initialState
        }
        default:
            return state;
    }
};

export default reducer;
