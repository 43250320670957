import React, { ReactNode } from 'react';
import './TagLabel.less';
import { isDark } from '../../helper/ColorHelper';


const TagLabel = ({ children, label, color } : {children: ReactNode, label: string, color: string}) => {
    if(!label && !children)
        return null;

    const dark = color ? isDark(color) : false;

    return (
        <span className='tag-label-wrapper' style={{ backgroundColor: color || '#DDD' }}>
            <span className={'tag-label-tag'}
                  style={{
                      color: dark ? '#fff' : '#000',
                  }}
            >{children}</span>
            {label && (
                <span className={'tag-label-label'}>{label}</span>
            )}
        </span>
    );
};


export default TagLabel;
