import { createSelector } from 'reselect';
import { searchStateType } from './search.reducer';
import { DocumentSearchResult } from '../../types';


const getSubState = (state: any) => state.search as searchStateType;


const isLoading = createSelector(getSubState, state => state.loading);
const getResults = createSelector(getSubState, state => state.result);

const noSearch = createSelector(getSubState, state => state.noSearch)

const getNumberOfPages = createSelector(getResults, result => result?.totalPages);
const getNumberOfElements = createSelector(getResults, result => result?.totalElements);

const getPageSize = createSelector(getResults, result => result?.size || 25);
const getCurrentPage = createSelector(getResults, result => result?.number);

const getData = createSelector(getResults, result => result?.content as DocumentSearchResult[]);

const getExtendedValues = createSelector(getSubState, state => (state.extendedValues || {}) as any);

export default {
    getResults,
    getNumberOfElements, getNumberOfPages, getData,
    getPageSize,
    getCurrentPage,
    isLoading,
    getExtendedValues,
    noSearch,
};
