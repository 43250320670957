import { select, takeLatest } from 'redux-saga/effects';
import { documentsTypes } from './index';
import axios from 'axios';
import { LoginSelectors } from '../Login';
import { saveAs } from 'file-saver';
import { Document } from '../../types';
import mime from 'mime-types'



function* downloadDocument({ type, document }: { type: string, document: Document }) {
    try{
        let token: string = yield select(LoginSelectors.getToken);


        let {data} = yield axios.get(`/api/document/${document.id}/download`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            responseType: "blob"
        });



        console.log("Received file", data);
        const extension = mime.extension(document.mediaType || (data as Blob).type);
        saveAs(data, document.fileName || `${document.id}.${extension}`);
    } catch(e){
        console.log("Download failed",e);
    }
}

export default function* documentsSaga() {
    yield takeLatest(documentsTypes.DOCUMENT_DOWNLOAD, downloadDocument);
}
