import { DocumentResultResponse, GroupMetaResponse } from '../../types';

export const types = {

    LOAD_GROUPS: 'LOAD_GROUPS',
    LOAD_GROUPS_DONE: 'LOAD_GROUPS_DONE',

};


const loadGroups = (key: string, value: string, extendedSearch: any | undefined, page: number, pageSize = 25) => ({
    type: types.LOAD_GROUPS,
    key,
    value,
    extendedSearch,
    page, pageSize,
});

const loadGroupsDone = (data: DocumentResultResponse, meta: GroupMetaResponse) => ({type: types.LOAD_GROUPS_DONE, data, meta});

export default {
    loadGroups,
    loadGroupsDone,
};
