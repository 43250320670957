import React from 'react';
import './Avatar.less';
import { Avatar as AntAvatar } from 'antd';
import clsx from 'clsx';
import Icon  from '../Icon/Icon';

const Avatar = ({ firstname, lastname, tiny = false }) => {
    let avatarStyle = {};

    let userShortcut = firstname && lastname && `${firstname[0]}${lastname[0]}`;

    return (
        <AntAvatar
            className={clsx('avatar', tiny && 'avatar--tiny')}
            style={avatarStyle}
            size={'small'}
            icon={
                userShortcut ? undefined : (
                    <Icon path={Icon.Path.mdiAccount} size={'1em'} />
                )
            }
        >
            {userShortcut}
        </AntAvatar>
    );
};

export default Avatar;
