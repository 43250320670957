import { AnyAction } from 'redux';
import { DocumentResultResponse, GroupMetaResponse } from '../../types';
import { groupsTypes } from './index';



export interface groupsStateType {
    loading: boolean,
    result?: DocumentResultResponse,
    meta?: GroupMetaResponse,
}

const initialState: groupsStateType = {
    loading: false,
};

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case groupsTypes.LOAD_GROUPS: {
            return {
                ...state,
                loading: true,
                result: undefined,
            }
        }
        case groupsTypes.LOAD_GROUPS_DONE: {
            return {
                ...state,
                loading: false,
                result: action.data,
                meta: action.meta,
            }
        }
        default:
            return state;
    }
};

export default reducer;
