import { DocumentSearchResultResponse } from '../../types';

export const types = {
    SEARCH: 'SEARCH',
    SEARCH_RESULT: 'SEARCH_RESULT',
    SEARCH_NO_SEARCH: 'SEARCH_NO_SEARCH',
};


const search = (search: string, keyType = 'ANY', extendedValues: any, page = 0, pageSize = 25) => ({
    type: types.SEARCH,
    search,
    keyType,
    extendedValues,
    page,
    pageSize,
});

const searchResult = (result: DocumentSearchResultResponse) => ({ type: types.SEARCH_RESULT, result });


const noSearch = () => ({ type: types.SEARCH_NO_SEARCH });


export default {
    search,
    searchResult,
    noSearch
};
