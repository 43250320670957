import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import LoginReducer from './Login'
import searchReducer from './search'
import groupsReducer from './groups'
import documentsReducer from './documents'

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    login: LoginReducer,
    search: searchReducer,
    groups: groupsReducer,
    documents: documentsReducer,
});

export default createRootReducer;
