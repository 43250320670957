import { Collapse, Row, Col, Input, Select, DatePicker } from 'antd';
import './ExtendedSearch.less';
import OptionalLabeledRow from '../OptionalLabeledRow/OptionalLabeledRow';
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { DocumentType, ExtendedSearchValueType } from '../../types';



const ExtendedSearch = ({ onChange, value }: { onChange: (values: ExtendedSearchValueType) => (void), value: ExtendedSearchValueType }) => {

    const [documentType, setDocumentType] = useState(value.documentType || 'ANY');
    const [createDateFrom, setCreateDateFrom] = useState<moment.Moment | null>(value.eventCreated?.after ? moment(value.eventCreated?.after) : null);
    const [createDateTo, setCreateDateTo] = useState<moment.Moment | null>(value.eventCreated?.before ? moment(value.eventCreated?.before) : null);

    useEffect(() => {
        onChange({
            eventCreated: {
                after: createDateFrom?.toISOString(),
                before: createDateTo?.toISOString(),
            },
            documentType: documentType === DocumentType.ANY ? undefined : documentType,
        });
    }, [documentType, createDateFrom, createDateTo]);

    return <div style={{ flex: 1 }}>
        <Collapse defaultActiveKey={['1']} ghost bordered={false} className={'extended-search'}>
            <Collapse.Panel className={'extended-search-panel'} header='Erweiterte Suche' key='1' style={{ flex: 1 }}>
                <Row gutter={[4, 4]}>
                    <Col span={24}>
                        <OptionalLabeledRow label={'Dokumenten-Typ'}>
                            <Select style={{ flex: 1, width: 260 }} value={documentType} onChange={setDocumentType}>
                                <Select.Option value={DocumentType.ANY}><i>Beliebig</i></Select.Option>
                                <Select.Option value={DocumentType.INVOICE}>Rechnung</Select.Option>
                                <Select.Option value={DocumentType.BILLING_RELEVANT}>Abrechnungsrelevant</Select.Option>
                                <Select.Option value={DocumentType.UNKNOWN}>Unbekannt</Select.Option>
                            </Select>
                        </OptionalLabeledRow>
                    </Col>
                    <Col span={24}>
                        <OptionalLabeledRow label={'Erstelldatum'}>
                            <DatePicker.RangePicker
                                value={[createDateFrom, createDateTo]}
                                onChange={(values: any, str) => {
                                    if (values) {
                                        setCreateDateFrom(values[0].startOf('month'));
                                        setCreateDateTo(values[1].endOf('month'));
                                    } else {
                                        setCreateDateTo(null);
                                        setCreateDateFrom(null);
                                    }
                                }}
                                style={{ flex: 1, width: 260 }}
                                format={'MM.YYYY'}
                                allowClear={true}
                                disabledDate={date => date.isAfter(moment())}
                                picker={'month'}
                            />
                        </OptionalLabeledRow>
                    </Col>
                </Row>


                {/*<Row style={{flex: 1}}>*/}
                {/*    <Col>*/}
                {/*        Dokumenten-Typ*/}
                {/*    </Col>*/}
                {/*    <Col>*/}
                {/*        */}
                {/*    </Col>*/}
                {/*</Row>*/}
            </Collapse.Panel>

        </Collapse>
    </div>;
};

export default ExtendedSearch;
