import { Col, List, Row, Tag } from 'antd';
import { DocumentSearchResult } from '../../types';
import Icon from '../Icon/Icon';
import Link from '../Link/Link';
import TagLabel from '../TagLabel/TagLabel';
import { getColorFromString } from '../../helper/ColorHelper';
import { useCallback, useMemo } from 'react';


const SearchResultListItem = ({ result }: { result: DocumentSearchResult }) => {

    const documentSum = useMemo(()=>{
        return result.keys.reduce((result, k) => result + (k.resultDetails?.documentsSize || 0), 0)
    },[result.keys])


    return (
        <List.Item className={'search-result-list-item'}>
            <Link to={`/group/${result.keys[0].key}/${result.keys[0].value}/`} style={{ flex: 1 }}>
                <Row gutter={[4, 4]} align={'middle'} style={{ flex: 1 }}>
                    <Col style={{ marginRight: 12 }}>
                        <Icon
                            style={{
                                backgroundColor: '#999',
                                borderRadius: 999,
                                padding: 8,
                                fontSize: '4em',
                                color: '#fff',
                            }}
                            path={Icon.Path.mdiMagnify} />
                    </Col>
                    <Col flex={1}>
                        <Row align={'middle'}>
                            <Col span={24}>
                                {
                                    result.keys.map((k, index) => {
                                        return <TagLabel key={`${k.key}-${index}`} label={k.value}
                                                         color={getColorFromString(k.key)}>{k.readableKey || k.key}</TagLabel>;
                                    })
                                }
                            </Col>
                            <Col span={24}>
                                <span style={{ fontSize: '1.4em', fontWeight: 500 }}>{result.keys[0].value}</span>
                            </Col>
                        </Row>
                    </Col>
                    <Col flex={0}>
                        <div className={'small'}>
                            {`${documentSum} Dokumente`}
                        </div>
                    </Col>
                </Row>
            </Link>
        </List.Item>
    );
};
export default SearchResultListItem;
