import { createSelector } from 'reselect';
import { LoginStateType } from './Login.types';

const getSubState = (state: any) => state.login as LoginStateType;

const isLoggedIn = createSelector(getSubState, login => login.loggedIn)
const isLoading = createSelector(getSubState, login => login.loading)
const hasError = createSelector(getSubState, login => login.error)

const getUser = createSelector(getSubState, login => login.login)

const getToken = createSelector(getUser, user => user?.token)
const getExpires = createSelector(getUser, user => user?.expires)

export default {
    isLoggedIn,
    isLoading,
    hasError,
    getUser,
    getToken,
    getExpires
};
