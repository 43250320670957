import React, {ReactNode} from 'react';
import './MainScreen.less';
import { Layout } from 'antd';
import Link from '../../components/Link/Link';
import UserMenu from './mainmenu/UserMenu/UserMenu';
import MainMenu from './mainmenu';

const MainScreen = ({children}: { children: ReactNode }) => {
    return (
        <div className={'main'}>
            <Layout className={'layout-wrapper'}>
                <Layout.Header className='header-wrapper'>
                    <div className={'header'}>
                        <div>
                            <Link to={'/'}>
                                <div className='logo'>
                                    <img alt={'envago logo'}
                                         src={require('../../assets/small_logo_white_transparent.png').default}/>
                                </div>
                            </Link>
                        </div>

                        <div style={{flex: 1}}/>
                        <MainMenu/>
                        <UserMenu/>
                    </div>
                </Layout.Header>
                <Layout.Content className={'content-wrapper'}>
                   <div className={'content'}>
                       {children}
                   </div>
                </Layout.Content>
            </Layout>
        </div>
    );
};

export default MainScreen;
