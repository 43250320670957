import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './root.reducers';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import AsyncStorage from '@react-native-community/async-storage';
import { persistStore, persistReducer } from 'redux-persist';

const persistConfig = {
    key: 'envago:document',
    storage: AsyncStorage,
    whitelist: ['login'],
};

export const history = createBrowserHistory();

export default function configureStore(preloadedState) {

    const sagaMiddleware = createSagaMiddleware();

    const persistedReducer = persistReducer(persistConfig,
        createRootReducer(history));

    let store;
    if (process.env.NODE_ENV === 'development') {
        store = createStore(
            persistedReducer, // root reducer with router state
            preloadedState,
            compose(
                applyMiddleware(
                    logger,
                    routerMiddleware(history), // for dispatching history actions
                    // ... other middlewares ...
                    sagaMiddleware,
                ),
            ),
        );
    } else {

        store = createStore(
            persistedReducer, // root reducer with router state
            preloadedState,
            compose(
                applyMiddleware(
                    // logger,
                    routerMiddleware(history), // for dispatching history actions
                    // ... other middlewares ...
                    sagaMiddleware,
                ),
            ),
        );
    }

    const persistor = persistStore(store);

    return {
        store,
        persistor,
        runSaga: sagaMiddleware.run,
    };
}
