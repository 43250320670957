import { AnyAction } from 'redux';


export interface documentsStateType {

}


const initialState: documentsStateType = {};

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {

        default:
            return state;
    }
};

export default reducer;
