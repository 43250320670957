import React from 'react';
import './SearchResultList.less';
import { DocumentSearchResult } from '../../types';
import { Card, List } from 'antd';
import SearchResultListItem from './SeachResultListItem';


interface SearchResultListParms {
    loading: boolean,
    currentPage?: number,
    pageSize?: number,
    totalElements?: number,
    results: DocumentSearchResult[],
    onPaginationChange: (page: number, pageSize?: number) => (void),
}

const SearchResultList = ({
                              results,
                              loading,
                              currentPage,
                              pageSize,
                              totalElements,
                              onPaginationChange,
                          }: SearchResultListParms) => {
    return (
        <Card size={'small'} className='search-result-list'>
            <List dataSource={results}
                  pagination={{
                      total: totalElements,
                      pageSize: pageSize,
                      defaultPageSize: 25,
                      size: 'small',
                      onChange: onPaginationChange,
                      showSizeChanger: false,
                      current: currentPage,
                  }}
                  loading={loading}
                  size={'small'}
                  renderItem={(result, index) => <SearchResultListItem result={result}
                                                                       key={`search-result-${index}`} />}
            />
        </Card>
    );
};


export default SearchResultList;
