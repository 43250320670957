import React, {useEffect, useState} from 'react';
import './DocumentPreview.less';
import {Document} from '../../../types';
import {Button, Image, notification} from 'antd';
import axios from 'axios';
import {useSelector} from 'react-redux';
import {LoginSelectors} from '../../../redux/Login';
import PDFAttachment from '../../../components/PDFAttachment/PDFAttachment';
import {documentsActions} from '../../../redux/documents';
import Icon, {InlineIcon} from '../../../components/Icon/Icon';
import PreviewDocument from "../../../components/PreviewDocument";

const DocumentPreview = ({document}: { document: Document }) => {

    let token = useSelector(LoginSelectors.getToken);

    let [imageUrl, setImageUrl] = useState<string>();
    let [documentType, setDocumentType] = useState<string>();
    let [isVisible, setVisible] = useState(false);
    let [error, setError] = useState(false)

    const download = async () => {
        // if(imageUrl){
        //     console.log("Already loaded");
        //     setVisible(true);
        //     return;
        // }
        try {
            let {data} = await axios.get(`/api/document/${document.id}/download`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                responseType: 'blob',
            });
            console.log('DownloadData', data);
            let urlObject = URL.createObjectURL(data);
            // setDocumentType((data as Blob).type);
            setDocumentType(document.mediaType || (data as Blob).type);
            setImageUrl(urlObject);
            setVisible(true);
        } catch (e) {
            notification['error']({
                message: 'Dateizugriff fehlgeschlagen',
                description: <p>
                    Auf die Datei konnte nicht zugegriffen werden. Eventuell ist das Dokument nicht mehr verfügbar.
                </p>,
            });
            setError(true);
        }
    };

    return (
        <div className='document-preview'>
            <Button
                style={{padding: 0}}
                onClick={() => {
                    download();
                }}
                size={'small'} type={'link'} icon={
                <InlineIcon style={{marginRight: 4,}} size={'1rem'} path={Icon.Path.mdiEye}/>
            }>Vorschau</Button>

            <PreviewDocument
                visible={isVisible}
                onVisibleChange={(visible => setVisible(visible))}
                mediaType={document.mediaType}
                imageUrl={imageUrl}/>
        </div>
    );
};

export default DocumentPreview;
