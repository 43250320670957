import React, { useCallback, useEffect, useRef, useState } from 'react';
import './SearchBar.less';
import { Button, Col, Input, Row, Select } from 'antd';
import Icon from '../Icon/Icon';
import ExtendedSearch from './ExtendedSearch';
import { ExtendedSearchValueType } from '../../types';

const { Option } = Select;

const SearchBar = ({
                       onChange,
                       onSubmit,
    documentType,
    value, extendedValues: extExtendedValues
                   }: {
    onChange: (text: string, type: string, extendedValues: any) => (void),
    onSubmit: (text: string, type: string, extendedValues: any) => (void),
    documentType: string, value: string, extendedValues: ExtendedSearchValueType,
}) => {

    const [selectedType, setSelectedType] = useState(documentType);
    const [searchValue, setSearchValue] = useState(value);
    const [extendedValues, setExtendedValues] = useState<any>(extExtendedValues);

    const inputRef = useRef<Input>(null);

    const submit = useCallback(() => {
        onSubmit(searchValue, selectedType, extendedValues);
    }, []);

    useEffect(() => {
        onChange(searchValue, selectedType, extendedValues);
    }, [searchValue, selectedType, extendedValues]);

    useEffect(() => {
        if (inputRef.current)
            inputRef.current.focus();
    }, [selectedType]);

    // useEffect(()=>{
    //     if(value !== searchValue)
    //         setSearchValue(value)
    // }, [value])

    return (
        <div className='search-bar'>
            <Row gutter={[4, 4]}>
                <Col flex={1} style={{ display: 'flex', maxWidth: 150 }}>
                    <Select style={{ flex: 1 }} value={selectedType} onChange={setSelectedType}>
                        <Select.Option value={'ANY'}><i>Beliebig</i></Select.Option>
                        <Select.Option value={'MELO'}>MeLo</Select.Option>
                        <Select.Option value={'MALO'}>MaLo</Select.Option>
                        <Select.Option value={'METER_NUMBER'}>Zählernummer</Select.Option>
                        <Select.Option value={'CUSTOMER_NUMBER'}>Kundennummer</Select.Option>
                    </Select>
                </Col>
                <Col flex={1}>
                    <Input
                        placeholder={'Suche'}
                        value={searchValue}
                        onChange={e => setSearchValue(e.target.value)}
                        onPressEnter={submit} autoFocus={true} ref={inputRef} />
                </Col>
                <Col>
                    <Button icon={<Icon style={{ fontSize: '2em', color: '#fff' }} path={Icon.Path.mdiMagnify} />}
                            type={'primary'}
                            onClick={submit} />
                </Col>
            </Row>
            <Row style={{ marginTop: 4 }}>
                <ExtendedSearch
                    value={extendedValues}
                    onChange={values => {
                    console.log('setExtendedValues', values);
                    setExtendedValues(values);
                }} />
            </Row>
        </div>
    );
};

export default SearchBar;
