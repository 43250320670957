export interface Document {
    id: string,
    tenant: string,

    keys: Key[],
    tags?: Tag[],

    fileName: string,
    mediaType?: string,

    documentType: DocumentType,

    uploadedDate: string,
    eventCreateDate: string,
    created: string,

    createdByUser: string,
    createdBySystem: string,

    expiresAt: string,
}

export enum DocumentType {
    ANY = 'ANY',
    INVOICE = 'INVOICE',
    BILLING_RELEVANT = 'BILLING_RELEVANT',
    UNKNOWN = 'UNKNOWN',
}

interface Key {
    key: string,
    readableKey?: string,
    value: string,
}

interface Tag {
    key: string,
    value: string,
    readableValue?: string,
}

export interface DocumentSearchRequest {
    documentType: DocumentType,
    keys: {
        key?: string,
        value: string,
    }[],
    eventCreated?: {
        after?: string,
        before?: string,
    }
}

export interface DocumentSearchResult {

    keys: {
        key?: string,
        readableKey?: string,
        value: string,
        resultDetails?: {
            documentsSize: number,
            earliestCreated?: string | null,
            latestCreated?: string,
            earliestEventCreated?: string,
            latestEventCreated?: string,
        };
    }[],


}

export interface DocumentSearchResultResponse {
    content: DocumentSearchResult[],
    totalElements: number,
    size: number,
    totalPages: number,
    number: number,
}


export interface DocumentResultResponse {
    content: Document[],
    totalElements: number,
    size: number,
    totalPages: number,
    number: number,
}

export interface ExtendedSearchValueType {
    documentType?: string,
    eventCreated?: {
        after?: string,
        before?: string,
    }
}


export interface GroupMetaResponse {
    commonKeys: {
       key: string,
       readableKey?: string,
       value: string,
    }[]
}
