import {createSelector} from 'reselect';


const getCurrentPath: any = (state: any) => state.router?.location?.pathname || '/';

const getCurrentActiveRootPath: any = createSelector(getCurrentPath, (path : string) => {
    const firstComponentRegexResult = path.match(/^(\/[^\/]*)/);
    if (firstComponentRegexResult && firstComponentRegexResult[0])
        return firstComponentRegexResult[0];
    return '/';
});


export default {
    getCurrentPath, getCurrentActiveRootPath
};
