import {Modal} from "antd";
import {useEffect, useState} from "react";
import './PreviewTextDocument.less'

type Props = {
    visible: boolean;
    onClose: () => void;
    imageUrl: string;
    mediaType: string;
}
const PreviewTextDocument = ({
                                 visible, onClose, imageUrl, mediaType
                             }: Props) => {

    let [data, setData] = useState<string|null>(null);

    useEffect(() => {
        fetch(imageUrl)
            .then(r => {
                r.blob().then(blob => {
                    console.log("blob", blob)
                    blob.text().then(text => setData(text))
                })

            })
    }, [])

    return <div>
        <Modal visible={visible}
               onCancel={onClose}
               onOk={onClose}
        >
            <div className={'text-file-preview'}>
                <code>
                    {data}
                </code>
            </div>
        </Modal>
    </div>
}

export default PreviewTextDocument
