import {Document} from '../../types';

export const types = {

    DOCUMENT_DOWNLOAD : 'DOCUMENT_DOWNLOAD'

};


const downloadDocument = (document: Document) => ({type: types.DOCUMENT_DOWNLOAD, document})

export default {
    downloadDocument,
};
