

const config = {
    oauth: {
        clientId: process.env.REACT_APP_ENVAGO_CLIENT_ID || "envago.document.dev",
        clientSecret: process.env.REACT_APP_ENVAGO_CLIENT_SECRET || "FoY46ZmoMD2AIIjulivfzVwkfuhXrtmIQc6KeDQGApmZoshmg1SvfugN5ahT",
        accessTokenUri: process.env.REACT_APP_ENVAGO_ACCESS_TOKEN_URI || "https://login.dev.cluster.envago.de/oauth/token",
        authorizationUri: process.env.REACT_APP_ENVAGO_AUTHORIZATION_URI || "https://login.dev.cluster.envago.de/oauth/authorize",
    },
};


console.log("Config", config)

export default config;
