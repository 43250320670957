import React from 'react';
import './config';
import './App.less';
import { Provider } from 'react-redux';
import configureStore, { history } from './redux/store';
import rootSaga from './redux/root.saga';
import { ConnectedRouter } from 'connected-react-router';
import MainScreen from './screens/MainScreen/MainScreen';
import AuthenticationScreen from './screens/AuthenticationScreen/AuthenticationScreen';
import Routes from './screens/navigation.routes';
import { PersistGate } from 'redux-persist/integration/react';
import { Auth0Provider } from '@auth0/auth0-react'; // react-router v4/v5


const { store, runSaga, persistor } = configureStore();
runSaga(rootSaga);

const host = () => process.env.REACT_APP_HOST || '';
const redirectUri = () => `${host()}/callback`;


function App() {
    return (
        <div className='App'>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <ConnectedRouter history={history}>

                        <AuthenticationScreen>
                            <MainScreen>
                                <Routes />
                            </MainScreen>
                        </AuthenticationScreen>
                    </ConnectedRouter>
                </PersistGate>
            </Provider>
        </div>
    );
}

export default App;
