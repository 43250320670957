import React from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

type MenuItemProps = {
    path: string;
    icon?: React.ReactNode;
    title: string;
};

const MenuItem = ({ path, icon, title }: MenuItemProps) => {
    const dispatch = useDispatch();
    return (
        <a
            onClick={() => {
                dispatch(push(path));
            }}
        >
            <div>
                {icon}
                {title}
            </div>
        </a>
    );
};

export default MenuItem;
