import React from 'react';
import './OptionalLabeledRow.less';
import { Col, Row } from 'antd';

/**
 *
 * @param id
 * @param label
 * @param value
 * @param children
 * @param labelSpan
 * @param showIf if undefined -> it's default value of true is taken! Careful. Set it to !!var if checking var existence
 * @param style
 * @returns {JSX.Element|null}
 * @constructor
 */
const OptionalLabeledRow = ({
    id,
    label,
    value,
    children,
    labelSpan = 8,
    showIf = true,
    style,
}: {
    id?: string;
    label: string;
    value?: React.ReactNode;
    children?: React.ReactNode | string;
    labelSpan?: number;
    showIf?: boolean;
    style?: React.CSSProperties;
}) => {
    if (!value && !children) return null;

    if (!showIf) return null;

    return (
        <Row className={'optional-labeled-row'} id={id} style={style}>
            <Col className={'optional-labeled-row--label label'} flex={'200px'} span={labelSpan}>
                {label}
            </Col>
            <Col
                className={'optional-labeled-row--value'}
                style={{
                    overflowWrap: 'break-word',
                }}
                span={labelSpan < 24 ? 24 - labelSpan : 24}
            >
                {value || children}
            </Col>
        </Row>
    );
};

export default OptionalLabeledRow;
