import React from 'react';
import './UserMenu.less';
import { Menu } from 'antd';
import User from '../../../../components/User/User';
import { useDispatch, useSelector } from 'react-redux';
import { LoginActions, LoginSelectors } from '../../../../redux/Login';


const UserMenu = ({}) => {

    const dispatch = useDispatch();
    const user = useSelector(LoginSelectors.getUser);

    return (

        <Menu theme='dark' mode='horizontal'
              onClick={({ key }) => {
                  if (key === 'logout') {
                      dispatch(LoginActions.logout());
                  }
              }}
        >
            <Menu.SubMenu
                popupClassName={'user-menu-sub-menu-popup'}
                title={<User showName={false} popoverEnabled={false} email={user.username} />}>
                <Menu.Item key={'logout'}>
                    Abmelden
                </Menu.Item>
            </Menu.SubMenu>
        </Menu>

    );
};

export default UserMenu;
