export const types = {
    LOGIN: 'LOGIN',
    LOGIN_DONE: 'LOGIN_DONE',
    LOGIN_ERROR: 'LOGIN_ERROR',
    LOGOUT: 'LOGOUT',
    LOGIN_ENVAGO: 'LOGIN_ENVAGO',
    LOGIN_ENVAGO_ERROR: 'LOGIN_ENVAGO_ERROR',
};

const login = (username: string, password: string, remember: boolean = false) => ({
    type: types.LOGIN,
    username,
    password,
    remember,
});

const loginError = () => ({
    type: types.LOGIN_ERROR,
});

const loginDone = (username: string, token: string, expires: number,) => ({
    type: types.LOGIN_DONE,
    username, token, expires,
});

const logout = () => ({
    type: types.LOGOUT,
});

const loginEnvago = (token:string) => ({
    type: types.LOGIN_ENVAGO, token,
});

const loginEnvagoError = () => ({
    type: types.LOGIN_ENVAGO_ERROR,
});

export default {
    login,
    loginDone,
    loginError,
    logout,
    loginEnvago,
    loginEnvagoError,
};
