import { takeLatest, select, put, debounce, call } from 'redux-saga/effects';
import { searchActions, searchTypes } from './index';

import axios from 'axios';
import { LoginSelectors } from '../Login';
import { Simulate } from 'react-dom/test-utils';
import { DocumentSearchRequest } from '../../types';


function* executeSearch({
                     type,
                     search,
                     keyType,
                     page,
                     pageSize,
                     extendedValues,
                 }: { type: any, search: string, keyType: string, page: number, pageSize: number, extendedValues: any }) : any {
    const token: string = yield select(LoginSelectors.getToken);


    let query: DocumentSearchRequest = {
        ...extendedValues,
    };

    if (search && search.trim().length > 3) {
        query = {
            ...query,
            keys: [{ key: keyType === 'ANY' ? undefined : keyType, value: search }],
        };
    } else {
        yield put(searchActions.noSearch());
        return;
    }

//     if(search && keyType){
//         query['keys'] = {key: keyType === 'ANY' ? undefined : keyType, value: search}
//     }
// }

    try {
        let { data } = yield axios.post(`/api/document/search`, query, {
            params: {
                page: page - 1, size: pageSize,
                sort: 'eventCreated',
                'eventCreated.dir': 'DESC',
            },
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });



        if (data.numberOfElements === 0 && page > 1) {
            console.log("wrong page - reset to 0")
            yield call(executeSearch, {type,
                search,
                keyType,
                page: 0,
                pageSize,
                extendedValues,})
            return;
        }

        yield put(searchActions.searchResult(data));
    } catch (e) {
        console.log(e);
    }


    // console.log('Search for', type, search, keyType);
}


export default function* searchSaga() {
    // console.log('SearchSaga');
    yield debounce(500, searchTypes.SEARCH, executeSearch);
    // yield takeLatest(searchTypes.SEARCH, search);
}
