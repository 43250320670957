import React, { CSSProperties } from 'react';
import './Icon.less';
import MdiIcon from '@mdi/react';
// import * as Path from '@mdi/js'
import {
    mdiMagnify,
    mdiAccount,
    mdiLock,
    mdiFilePdfOutline,
    mdiFileOutline,
    mdiFileImageOutline,
    mdiDownload,
    mdiDownloadCircle,
    mdiEye,
    mdiArrowRight,
    mdiArrowLeft,
    mdiFileDocumentOutline,
    mdiFileCodeOutline
} from '@mdi/js';
import classNames from 'classnames';


export const InlineIcon = ({
                               style,
                               path,
                               className,
                               size = '1em',
                           }: { style?: CSSProperties, className?: string, path: string, size?: number | string }) => {
    return (
        <MdiIcon style={{
            ...style,
            verticalAlign: 'middle',
            marginBottom: 2,
            height: size,
        }} className={classNames(className, 'icon')} path={path} />
    );
};

const Icon = ({
                  style,
                  path,
                  className,
                  size = '1em',
              }: { style?: CSSProperties, className?: string, path: string, size?: number | string }) => {
    return (
        <span className={'icon-wrapper'}>
        <MdiIcon style={{
            ...style,
            fontSize: size,
        }} className={classNames(className, 'icon')} path={path} />
            </span>
    );
};

// Icon.Path = Path;

Icon.Path = {
    mdiMagnify,
    mdiAccount,
    mdiLock,
    mdiFilePdfOutline,
    mdiFileOutline,
    mdiFileImageOutline,
    mdiDownload,
    mdiDownloadCircle,
    mdiEye,
    mdiArrowRight,
    mdiArrowLeft,
    mdiFileDocumentOutline,
    mdiFileCodeOutline
};

export default Icon;
