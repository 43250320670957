import React from 'react';
import './DateTimeSpan.less';
import moment from 'moment';

export enum DateFormat {
    DATE_FULL = 'DD.MM.YYYY',
    DATE_SHORT = 'DD.MM.',
    DATE_TIME_SHORT = 'DD.MM.YYYY HH:mm',
    DATE_TIME_FULL = 'DD.MM.YYYY HH:mm:ss',
}

interface DateTimeSpanTypeChildren {
    children: Date | string;
    format?: DateFormat;
}

interface DateTimeSpanTypeValue {
    value: Date | string;
    format?: DateFormat;
}


const DateTimeSpan = ({
    value,
    children,
    format,
}: XOR<DateTimeSpanTypeChildren, DateTimeSpanTypeValue>) => {
    if (!value && !children) return null;
    const momentDate = moment(value || children);

    /*
        check if only date was passed for parsing -> if so, dont show times
     */
    if (format !== undefined) {
    } else if (momentDate.creationData().format === 'YYYY-MM-DD') {
        format = DateFormat.DATE_FULL;
    } else {
        format = DateFormat.DATE_TIME_FULL;
    }

    return <span className="date-time-span">{momentDate.format(format)}</span>;
};



export default DateTimeSpan;
