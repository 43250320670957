import { createSelector } from 'reselect';
import { groupsStateType } from './groups.reducer';
import { Document } from '../../types';

const getSubState = (state: any) => state.groups as groupsStateType;


const isLoading = createSelector(getSubState, state => state.loading);

const getResults = createSelector(getSubState, state => state.result);

const getNumberOfPages = createSelector(getResults, result => result?.totalPages);
const getNumberOfElements = createSelector(getResults, result => result?.totalElements);

const getPageSize = createSelector(getResults, result => result?.size || 25);
const getCurrentPage = createSelector(getResults, result => result?.number);

const getData = createSelector(getResults, result => result?.content as Document[]);

const getMeta = createSelector(getSubState, state => state.meta);

const getCommonKeys = createSelector(getMeta, meta => meta?.commonKeys);

const getCommonKeysKeys = createSelector(getCommonKeys, keys => keys?.map(k => k.key));

export default {
    isLoading,
    getResults,
    getNumberOfElements,
    getNumberOfPages,
    getPageSize,
    getCurrentPage,
    getData,
    getMeta, getCommonKeys, getCommonKeysKeys
};
