export const translateTag = (key: string) => {
    switch (key) {
        case "TYPE":
            return "Typ";
        case "ABORT_REASON":
            return "Abbruchgrund";
        case "JOB_REASON":
            return "Auftragsgrund";
        case "METER_READING_RESULT_CHANNEL":
            return "Kanal";
        case "METER_READING_RESULT_SOURCE":
            return "Quelle";
        case "SAK_OUT":
            return "Brieftyp";
        // WEST Tags
        case 'JOB_TYPE':
            return "Auftragstyp";
        case 'DEVICE_TYPE':
            return "Gerät";
        case 'DEVICE_SUB_TYPE':
            return "Geräte Subtyp";
        case 'DEVICE_STATUS':
            return "Status";
        case 'FILE_SUB_TYPE':
            return "Datei Subtyp";
        case 'FILE_TYPE':
            return "Typ";
        default:
            return key;
    }
}
